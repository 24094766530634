html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main {
  background-color: #292929;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: -2;
  overflow-x: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.stickyBar {
  color: #242F40;
}

.font {
  color: #FFFFFF;
}

.underTextFont {
  color: #E5E5E5;
}

.goldenColor {
  color: #CCA43B;
}

header {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #363636;
  z-index: 100;
  left: 0;
}

footer {
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* background-color: #363636; */
}

.navigation {
  margin: auto;
}

.navigation ul {
  display: flex;
  padding: 0;
  margin: 0;
  font-size: 24px;
}

.navigation a {
  padding: 15px 40px 15px 40px;
  list-style: none;
  text-decoration: none;
  align-items: center;
  text-align: center;
  margin: auto;
  display: flex;
  color: #FFFFFF;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
  z-index: 10;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
  z-index: 12;
}

.change .bar2 {
  opacity: 0;
  z-index: 12;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
  z-index: 12;
}

/*Acceuil*/
.accueilContainer {
  display: flex;
  height: 100%;
  margin-top: 60px;
}

.accueilContainer,
.portfolioContainer {
  max-width: 1280px;
  height: 100%;
  z-index: 10;
  margin-top: 72px;
}

.accueilContainer h1 {
  font-size: 60px;
  margin-left: 40px;
  text-align: left;
  color: #FFFFFF;
}

.accueilContainer h1:after {
  content: "";
  display: block;
  height: 1px;
  background-color: #FFFFFF;
  margin-top: 10px;
  width: 1px;
  -webkit-animation: lineAfterH1 2s;
  animation: lineAfterH1 2s;
  animation-fill-mode: forwards;
}

/* keyframe afeter h1*/

@-webkit-keyframes lineAfterH1 {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0.6;

  }

  100% {
    opacity: 1;
    width: 440px;
  }
}

@keyframes lineAfterH1 {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
    width: 440px;
  }
}

.accueilContainer h2 {
  font-weight: 600;
  margin-left: 40px;
  text-align: left;
  color: #FFFFFF;
}

.accueilContainer h3 {
  font-weight: 300;
  margin-left: 40px;
  text-align: left;
  color: #FFFFFF;
}

.hidden {
  visibility: hidden;
}

/*Animation h1*/

.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-contract-bck 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

/*Animation h2*/

.tracking-in-expand-fwd-top {
  -webkit-animation: tracking-in-expand-fwd-top 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-expand-fwd-top 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

}

@-webkit-keyframes tracking-in-expand-fwd-top {
  0% {
    -webkit-transform: translateZ(400px) translateY(-300px);
    transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }

  60% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@keyframes tracking-in-expand-fwd-top {
  0% {
    -webkit-transform: translateZ(400px) translateY(-300px);
    transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}


/*Animation h3*/
.text-focus-in {
  -webkit-animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}




.navigation a:hover {
  color: #CCA43B;

}

/* Menu animation */
.snip1198 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;

}

.snip1198 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  font-size: 22px;
}

.snip1198 a {
  display: inline-block;
  list-style: outside none none;
  margin: 0.4em 0.8em;
  padding: 0;
}

.snip1198 li {
  padding: 0 0.7em;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.snip1198 li:before {
  position: absolute;
  content: '';
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  opacity: 0;
  left: 15%;
  right: 15%;
  top: 0;
  bottom: 0;
  border-left: 3px solid #CCA43B;
  border-right: 3px solid #CCA43B;
}

.snip1198 li:hover,
.snip1198 .nav-active li {
  color: #FFFFFF;
}

.snip1198 li:hover:before,
.snip1198 .nav-active li:before {
  opacity: 1;
  left: 0;
  right: 0;
}

/*portfolio*/
.portfolioTitle {
  text-align: center;
  margin: 15px;
}

.portfolioContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}

.ProjectCardL {
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #363636;
  border-radius: 15px;
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.ProjectCardL__Capture {
  background-position: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

}

.ProjectCardL__Head {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.ProjectCardL__Content {
  padding: 15px;
  height: 100%;
  flex-direction: column;
  display: flex;
  gap: 14px;
}

.ProjectCardL__More--btn {
  font-size: 18px;
  border: transparent;
  padding: 4px 8px;
  background: rgb(100, 100, 100);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.ProjectCardL__More--btn:hover {
  color: black;
  background: rgb(255, 255, 255);

}

.ProjectCardL__Title {
  height: 40%;
  font-size: 25px;
}

.ProjectCardS__Title {
  font-size: 25px;
}


.ProjectCardL__Head--logo>img {
  height: 100px;
}

.ProjectCardL__Head--Links {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px
}

.ProjectCardL__Head--LinksOut img {
  width: 50px;
  transition: all .2s ease-in-out;
}

.ProjectCardL__Head--LinksOut a:hover img {
  transform: scale(1.2);
}

.ProjectCardL__Head--LinksGit img {
  width: 50px;
  transition: all .2s ease-in-out;
}

.ProjectCardL__Head--LinksGit a:hover img {
  transform: scale(1.2);
}


.ProjectCardL__More {
  height: 25%;
}

.ProjectCardL__Techno {
  overflow-y: auto;
  height: 50%;
  display: flex;
  align-items: end;
  gap: 12px;
}

.ProjectCardL__Techno p {
  font-size: 16px !important;
  border: 2px solid white;
  border-radius: 50px;
  padding: 4px 8px;
  display: flex;
}

.OCback img {
  margin-top: -50px;
  background-color: white;
  color: #000;
}

.OCback h4,
.OCback p {
  color: #000 !important;
}

.OCback {
  background-color: white !important;
}

.OCback h4:hover {
  color: #CCA43B;
  transition: all 0.35s ease;
}

.flip-card-front img,
.flip-card-back img {
  width: 100%;


  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: -1;
  /*filter: blur(1px)*/
}

.flip-card-back a,
.flip-card-back h4 {
  text-decoration: none;
  font-weight: 600;
  margin: auto;
  text-align: center;
  align-items: center;
  color: #FFFFFF;
  vertical-align: middle;
  justify-content: center;
  margin-top: 175px !important;
}

.flip-card-back h4:hover {
  color: #CCA43B;
  transition: all 0.35s ease;
}

.OC {
  background-color: #7451eb;
  box-shadow: -1px 8px 15px -3px #000000;
  -webkit-box-shadow: -1px 8px 15px -3px #000000;
}

.portfolioTitle:after {
  content: "";
  display: block;
  height: 1px;
  background-color: #FFFFFF;
  margin-top: 10px;
  width: 1px;
  justify-content: center;
  animation: lineAfterH1 2s;
  animation-fill-mode: forwards;
  margin: auto;
}

.portfolio {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: space-evenly;
  margin-top: 25px;
  perspective: 1000px;

}



.portfolio h4 {
  font-weight: 600;
  margin: auto;
  text-align: center;
  align-items: center;
  color: #FFFFFF;
  vertical-align: middle;
  justify-content: center;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  width: 300px;
  height: 400px;
  display: flex;
  margin: 15px;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
  overflow: hidden;
  z-index: -2;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: -1px 1px 4px 0px rgb(255 255 255 / 35%) inset, -1px 8px 15px -3px #000000;
  -webkit-box-shadow: -1px 1px 4px 0px rgb(255 255 255 / 35%) inset, -1px 8px 15px -3px #000000;
}

/* Style the back side */
.flip-card-back {
  background-color: #363636;
  color: white;
  transform: rotateY(180deg);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  height: 100%;
  margin-left: -10px;
  box-shadow: -1px 1px 4px 0px rgb(255 255 255 / 35%) inset, -1px 8px 15px -3px #000000;
  -webkit-box-shadow: -1px 1px 4px 0px rgb(255 255 255 / 35%) inset, -1px 8px 15px -3px #000000;
}


.OCback {
  cursor: pointer;
}

.cardContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  gap: 50px;
  margin-bottom: 20px;
}

.cardContainer__side {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 50%;
}



.cardContainer__side--title {
  text-align: center;
}


.ProjectCardS {
  display: flex;
  border-radius: 15px;
  background-color: #363636;
  padding: 15px;
  justify-content: space-between;
  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: .2s;
}

.ProjectCardS__Text {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 15px;
}

.ProjectCardS__btn {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProjectCardS__btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: rgb(100, 100, 100);
  color: white;
  cursor: pointer;
  transition: all .2s ease-in-out;
  border: transparent;
  font-size: 40px;
}

.ProjectCardS__btn button:hover {
  color: black;
  background: rgb(255, 255, 255);

}

.card {
  max-width: 30%;
  margin: 10px;
  transition: all 200ms ease-in;
  transform: scale(1);
}

.portfolioImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.card:hover {
  transition: all 200ms ease-in;
  transform: scale(1.1);
  -webkit-box-shadow: 0px 0px 15px 0px #2b2b2b;
  box-shadow: 0px 0px 15px 0px #2b2b2b;
}

.cardOver {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  background-color: #0000006e;
  opacity: 0;
  transition: all 200ms ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cardOver h4 {
  text-align: center;
  margin-bottom: 15px;
}

.cardOver:hover {
  opacity: 1;
  transition: all 200ms ease-in;
}

.cardOver__button {
  background-color: transparent;
  border: transparent;

}

.cardOver__buttonImg {
  width: 50px;
  transition: all 150ms ease-in;
  transform: scale(1);
  cursor: pointer;
}

.cardOver__buttonImg:hover {
  transition: all 150ms ease-in;
  transform: scale(1.2);

}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .modal {
  display: none;
}

.active-modal {
  display: block;
} */

.close-modal {
  margin-bottom: 25px;
  width: 50px;
  height: 50px;
  top: 60px;
  position: absolute;
  right: 20px;
  font-size: 36px;
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.showProject {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.904);
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.portfolio__TitleContainer {
  display: flex;
  margin-top: 90px;

}

.portfolio__technoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

}

.portfolio__technoContainer {
  display: flex;
  justify-content: space-between;
  gap: 14px;
}

.portfolio__technoSection h4 {
  margin-bottom: 15px;
}

.portfolio__TextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.portfolio__TextContainer h4 {
  text-align: center;
  margin-bottom: 15px;
}

.portfolio__TextContainer p {
  margin: auto;
  max-width: 90%;
  line-height: 150%;
}

.portfolio__TextContainer a {
  margin-top: 15px;
  margin-bottom: 15px;
  text-decoration: none;
  cursor: pointer;
  color: #CCA43B;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.portfolio__TextContainer a:hover {
  color: white;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.portfolio__TitleContainer h3 {
  margin: auto;
  margin-left: 20px;
}

.portfolio__ImgTitle {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.portfolio__technoImg {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.portfolio__backButton {
  background-color: transparent;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  transform: scale(1);
  cursor: pointer;
  display: flex;
  margin-top: 20px;
  border: transparent;
}

.portfolio__backButton:hover {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  transform: scale(1.2);
}

.hideProject {
  display: none;
}


/* Footer */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-links {
  display: flex;
  padding-bottom: 20px;
  -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.social-btn {
  cursor: pointer;
  height: 50px;
  width: 50px;
  font-family: 'Titillium Web', sans-serif;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  background: white;
  margin: 5px;
  transition: 1s;
}

.social-links a {
  text-decoration: none;
  width: 0px;
  overflow: hidden;
  transition: 1s;
  text-align: center;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.social-btn:hover a {
  padding: 2px;
  width: max-content;
  font-size: 20px;
}


.social-btn span {
  width: 0px;
  overflow: hidden;
  transition: 1s;
  text-align: center;
}

.social-btn:hover {
  width: 200px;
  border-radius: 5px;
}

.social-btn:hover span {
  padding: 2px;
  width: max-content;
  font-size: 20px;
}

#twitter svg {
  fill: #1da1f2;
}

#linkedin svg {
  fill: #0e76a8;
  padding-bottom: 5px;
}

#github {
  fill: #333;
}

/* Glow effect */

.linear-wipe {
  text-align: center;

  background: linear-gradient(to right, #FFF 20%, #FFF 40%, #CCA43B 60%, #CCA43B 80%, #FFF 90%);
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 4s linear infinite;


}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.profilePictureContainer {
  height: 400px;
  width: 400px;
  border-radius: 50%;
  border: transparent solid 1px;
  overflow: hidden;
  display: inline-block;
  margin: auto;
  min-height: 380px;
  min-width: 380px;
}

.profilePicture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.accueil {
  display: flex;
  position: relative;
  z-index: 10;
}

.accueil__text {
  display: flex;
  flex-direction: column;
  margin: auto;
}

/*Compétences*/
.competencesContainer__competences__cards--globe {
  min-width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.competencesContainer__competences__cards--globeHideAnimation {
  height: 100%;
  background-color: #292929;
  /* background-color: gray; */
  width: 100%;
  position: absolute;
  z-index: 100;
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
  animation-delay: 1.5s;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.competencesContainer {
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  margin-top: 58px;
  justify-content: center;
  height: 100%;
}


.competencesContainer__textHeader p {
  font-size: 22px !important;
  margin-bottom: 30px;
  margin-top: 10px;
}

.competencesContainer__textHeader a {
  font-size: 22px !important;
}

.competencesContainer h3,
p,
a {
  text-align: center;
  font-size: calc(10px + 2vmin) !important;
}

.competencesContainer__competences {
  display: flex;
  /*margin: auto;*/
  justify-content: space-around;
}

.competencesContainer__competences__cards {
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
}



.competencesContainer__competences__cards p {
  font-size: 20px !important;
}

.competencesContainer__competences__cards h4 {
  color: #CCA43B;
  text-align: center;
}


.competencesContainer__competences__cards h5 {
  text-align: center;
  margin-top: 5px;
}

.competencesContainer__competences__cards h4:after {
  content: "";
  display: block;
  height: 1px;
  background-color: #CCA43B;
  margin-top: 5px;
  width: 1px;
  justify-content: center;
  animation: lineAfterH4 2s;

  animation-fill-mode: forwards;

}

@-webkit-keyframes lineAfterH4 {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0.6;

  }

  100% {
    opacity: 1;
    width: 100%;
  }
}

@keyframes lineAfterH4 {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
    width: 100%;
  }
}

.competencesContainer__competences__cards--front,
.competencesContainer__competences__cards--back,
.competencesContainer__competences__cards--platformes {
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  background-color: #363636;
}


.puff-in-center1 {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.puff-in-center2 {
  -webkit-animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.puff-in-center3 {
  -webkit-animation: puff-in-center 1.2s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 1.2s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.puff-in-center4 {
  -webkit-animation: puff-in-center 1.4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 1.4s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}


.puff-in-center5 {
  -webkit-animation: puff-in-center 1.6s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 1.6s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.puff-in-center6 {
  -webkit-animation: puff-in-center 1.8s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 1.8s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}


@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

/*Sphere Competences*/
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.tagcloud+div {
  display: none;
}

.tagcloud {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin: auto;
  width: 50%;
}

.tagcloud--item:hover {
  color: #36454F;
}


/*Contact */
.contactContainer {
  max-width: 1280px;
  width: 100%;
  position: relative;
  z-index: 10;
  margin-top: 60px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

#contact-form {
  max-width: 800px;
  margin: auto;
  animation: text-focus-in 1.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  width: 50%;
}

.formInput {
  width: 100%;
  min-height: 40px;
  margin-top: 15px;
  background-color: #363636;
  color: white;
  border: transparent;
  border-radius: 10px;
  padding-left: 15px;
  font-family: 'Montserrat', sans-serif;
}

.formInput::placeholder {
  color: white;
  margin-left: 50px;

  padding-top: 5px;
  font-size: 15px;

}

#contact-form textarea::placeholder {
  padding: 0;
}

#contact-form textarea {
  width: 100%;
  min-height: 100px;
  margin-top: 20px;
  padding-top: 10px;
  font-family: 'Montserrat', sans-serif;
}

#contact-form button {
  width: 100%;
  min-height: 40px;
  margin-top: 20px;
  background-color: #363636;
  color: white;
  font-size: 22px;
  border-radius: 10px;
  cursor: pointer;
  border: white 1px solid;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.successMessage {
  font-size: 20px;
  margin-top: 15px;
  color: #CCA43B;
}

#contact-form button:hover {
  background-color: #CCA43B;
  border: transparent;
  box-shadow: -1px 1px 4px 0px rgba(136, 136, 136, 0.35) inset;
  -webkit-box-shadow: -1px 1px 4px 0px rgba(255, 255, 255, 0.35) inset;
  -moz-box-shadow: -1px 1px 4px 0px rgba(126, 126, 126, 0.35) inset;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 45%;
  animation: text-focus-in 1.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.contactInfo a,
.competencesContainer a {
  text-decoration: none;
  color: white;
  font-size: 22px;
  margin-top: 20px;
  transition: all 0.35s ease;
}

.contactInfo a:hover,
.competencesContainer a:hover {
  color: #CCA43B;
}

.contactInfo p {
  display: flex;
  margin-top: 15px;
}

.contactInfo span {
  color: white;
  font-size: calc(10px + 2vmin);
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
}

.contactFormContainer {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  margin-top: 50px;
}

.contactForm {
  width: 55%;
}

.textContact {
  margin-top: 20px;
}


/* Media Queries */

@media (max-width: 1250px) {

  .portfolio,
  .accueil {
    flex-direction: column;
  }

  .accueilContainer h3 {
    margin-right: 10px;
  }


  .competencesContainer__competences__cards {
    width: 100%;


  }

  .competencesContainer__textHeader {
    margin: auto;
    width: 90%;
  }

  .competencesContainer__competences {
    margin: auto;


  }
}

@media (min-width: 520px) and (max-width: 944px) {
  .competencesContainer {
    margin-top: 222px;

  }

  .portfolioContainer {
    margin-top: 260px;
  }

  footer {
    margin-top: 220px;

  }
}


@media (max-width: 900px) {



  main {
    height: 100vh;
  }

  .contactFormContainer {
    flex-direction: column;
    margin-top: 20px;
  }

  #contact-form {
    width: 90%
  }

  .contactInfo {
    margin-top: 30px;
    width: 90%;

  }

  .contactInfo h3 {
    text-align: center;
  }

  .cardContainer {
    flex-direction: column;
    align-items: center;
  }

  .competencesContainer__competences {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .cardContainer__side {
    width: 75%;
  }

}

.mobileMenu {
  display: none;
}

@media (max-width: 840px) {
  .snip1198 {
    display: none !important;

  }

  .navigation {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-height: 60px;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .opended {
    height: 100vh;
    opacity: 0;
    -webkit-animation: opacityUp 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: opacityUp 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .navigation ul {
    flex-direction: column;
    margin-top: 60px;
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }


  .active {
    display: flex !important;
    -webkit-animation: opacityUp 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: opacityUp 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @keyframes opacityUp {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .mobileMenu {
    display: block;
    padding: 0;
    border: none;
    background: none;
    color: white;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 35px;
    height: 40px;
    margin-left: 10px;
    position: absolute;
    right: 20px;
    position: absolute;
    top: 0;
  }
}

@media (max-width: 520px) {
  .accueil h1 {
    flex-wrap: revert;
    margin-right: 15px;
  }

  .accueil h1:after {
    display: none;
  }

  .accueilContainer {
    margin-bottom: 20px;
  }

  .portfolioContainer {
    margin-bottom: 20px;
    position: relative;
    z-index: 10;
    height: auto;
  }

  .profilePictureContainer {
    width: 350px;
    height: 350px;
    min-width: 100px !important;
    min-height: 100px !important;
  }

  .portfolioContainer {
    margin-bottom: 20px;
  }

  .portfolioContainer p {}

  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  .ProjectCardS__Text {
    width: 80%;
  }

  .flip-card-inner {
    margin-left: -15px;
  }

  .competencesContainer__textHeader {
    width: 100%;
  }

  .competencesContainer__textHeader h2 {
    margin-left: 0;
  }

  .competencesContainer__textHeader p {
    margin-left: 10px;
    margin-right: 10px;
  }

  .competencesContainer {
    width: 100%;
    height: auto;
  }

  .contact h2 {
    margin-left: 0;
  }

}


@media (max-width: 360px) {
  .profilePictureContainer {
    width: 300px;
    height: 300px;
  }
}

/*Scroll Bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  max-height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #292929;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #363636;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
